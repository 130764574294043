import { Component, OnInit, HostListener, Inject } from "@angular/core";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import { ModalService } from "src/app/_modal/modal.service";
import { LetsTalkComponent } from "../lets-talk/lets-talk.component";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  letsTalkDialogueVisible = false; //Flag denote let's talk alert is shown or not
  routeSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    @Inject(DOCUMENT) document,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LetsTalkComponent>,
    private router: Router
  ) {
    this.routeSubscription = router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        $("#header").removeClass("header-up");
      });
  }

  ngOnInit(): void {
    var scroll_pos = 0;
    var scroll_time;

    $(window).scroll(function () {
      clearTimeout(scroll_time);
      var current_scroll = $(window).scrollTop();

      if (current_scroll >= $("#header").outerHeight()) {
        if (
          current_scroll <= scroll_pos ||
          $("#header").hasClass("suppress-scroll")
        ) {
          $("#header").removeClass("header-up");
        } else {
          $("#header").addClass("header-up");
        }
      }

      scroll_time = setTimeout(function () {
        scroll_pos = $(window).scrollTop();
      }, 100);
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      let element = document.getElementById("header");
      element.classList.add("sticky");
    } else {
      let element = document.getElementById("header");
      element.classList.remove("sticky");
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  firstClick() { }

  /**
   * Action on clicking the Let's Talk button
   */
  public onLetsTalkClick() {
    this.letsTalkDialogueVisible = true;
    this.dialogRef = this.dialog.open(LetsTalkComponent, {
      height: "100vh",
      width: "100vw",
      maxWidth: "100vw",
      maxHeight: "100vh",
    });
    this.dialogRef.afterClosed().subscribe(_ => {
      this.resetHambergerIcon();
    });
    $("#header").addClass("no-bg");
  }

  /**
   * Action on clicking Harmburger icon
   */
  public onHamburgerClick() {
    //If Let's talk dialogue is visible, dismiss the dialogue and remove the css class "open" to reset hamberger icon
    if (this.letsTalkDialogueVisible) {
      this.dialogRef.close();
      this.resetHambergerIcon();
      return;
    }

    //Else normal harmburgon click takes place
    var e = $("#navbar-toggle").data("scroll-y"),
      i = $(window).scrollTop(),
      n = $("#navbar-toggle"),
      o = $("#overlay-nav"),
      s = $("#header"),
      l = $("#letstalk"),
      r = $("body");
    o.hasClass("toggle")
      ? (r.css("top", "0px").removeClass("noscroll"),
        window.scrollTo(0, e),
        o.removeClass("toggle"),
        n.removeClass("open"),
        s.removeClass("overlay-nav-toggled"),
        s.removeClass("pos-fixed"),
        setTimeout(function () {
          s.removeClass("suppress-scroll");
          l.removeClass("hide");
        }, 700))
      : ($(this).data("scroll-y", i),
        o.addClass("toggle"),
        n.addClass("open"),
        l.addClass("hide"),
        s.addClass("overlay-nav-toggled suppress-scroll"),
        r.css("top", -i + "px").addClass("noscroll"));
  }

  /**
   * Helper methods
   */
  private resetHambergerIcon() {
    $("#header").removeClass("no-bg");
    this.letsTalkDialogueVisible = false;
    var n = $("#navbar-toggle");
    n.removeClass("open");
  }

}
