import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/landing-page/home/home.component";
// import { ServicesComponent } from "./components/services/services.component";
import { OurservicesComponent } from "./components/ourservices/ourservices.component";
import { IndustriesComponent } from "./components/industries/industries.component";
import { AboutusComponent } from "./components/aboutus/aboutus.component";
import { ProcessComponent } from "./components/process/process.component";
import { CareerComponent } from "./components/career/career.component";
import { ContactusComponent } from "./components/contactus/contactus.component";
import { EngagementModelComponent } from "./components/engagement-model/engagement-model.component";
import { CustomerEngagementComponent } from "./components/customer-engagement/customer-engagement.component";
import { TrustedPartnerComponent } from "./components/trusted-partner/trusted-partner.component";
import { SolutionProviderComponent } from "./components/solution-provider/solution-provider.component";
import { StartupEnterprisesComponent } from "./components/startup-enterprises/startup-enterprises.component";
import { TechnologiesComponent } from "./components/technologies/technologies.component";
import { SkillsetComponent } from "./components/skillset/skillset.component";
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

// const routes: Routes = [];
const routes: Routes = [
  { path: "", component: HomeComponent },
  // {
  //   path: "services/:serviceSlug",
  //   component: ServicesComponent
  // },
  {
    path: "ourservices/:serviceSlug",
    component: OurservicesComponent,
  },
  {
    path: "industries/:serviceSlug",
    component: IndustriesComponent,
  },
  {
    path: "aboutus",
    component: AboutusComponent,
  },
  {
    path: "process",
    component: ProcessComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "technologies",
    component: TechnologiesComponent,
  },
  {
    path: "career",
    component: CareerComponent,
  },
  {
    path: "contactus",
    component: ContactusComponent,
  },
  {
    path: "engagement-model",
    component: EngagementModelComponent,
  },
  {
    path: "customer-engagement",
    component: CustomerEngagementComponent,
  },
  {
    path: "trusted-partner",
    component: TrustedPartnerComponent,
  },
  {
    path: "solution-provider",
    component: SolutionProviderComponent,
  },
  {
    path: "startup-enterprises",
    component: StartupEnterprisesComponent,
  },
  {
    path: "skillset",
    component: SkillsetComponent,
  },
  {
    path: "item1",
    component: TechnologiesComponent,
  },
  {
    path: "item2",
    component: TechnologiesComponent,
  },
  {
    path: "item3",
    component: TechnologiesComponent,
  },
  {
    path: "item4",
    component: TechnologiesComponent,
  },
  {
    path: "item5",
    component: TechnologiesComponent,
  },
  {
    path: "item6",
    component: TechnologiesComponent,
  },
  {
    path: "item7",
    component: TechnologiesComponent,
  },
  {
    path: "item8",
    component: TechnologiesComponent,
  },
  {
    path: "item9",
    component: TechnologiesComponent,
  },
  {
    path: "item10",
    component: TechnologiesComponent,
  },
  {
    path: "item11",
    component: TechnologiesComponent,
  },
  {
    path: "item12",
    component: TechnologiesComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
