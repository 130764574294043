import { Injectable } from '@angular/core';
import blogJSON from '../data/blog.json'
import { blogs } from '../models/blog';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private httpService: HttpClient) { }
  getBlogListing(){
    // return blogJSON as blogs[];
    return this.httpService.get('https://www.calpinetech.com/data/blog.json');
  }
}
