import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private http: HttpClient,) { }

  private extractData(res: Response) {
    if (res.status < 200 || res.status >= 300) {
      throw new Error('Bad response status: ' + res.status);
    }
    const body = res;
    return body || {};
  }

  public postRequest(params, url): Observable<any> {
    return this.http.post(environment.urls.baseUrl + url, params,)
           .pipe(map(this.extractData));
  }

  getRequest(url): Observable<any> {
    return this.http.get( environment.urls.baseUrl + url,)
    .pipe(map(this.extractData));
  }


}
