import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-healthcare",
  templateUrl: "./healthcare.component.html",
  styleUrls: ["./healthcare.component.scss"],
})
export class HealthcareComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
