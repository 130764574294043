import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppConstants } from "src/app/utils/app-contants";

@Component({
  selector: "app-subsidebar-industries",
  templateUrl: "./subsidebar-industries.component.html",
  styleUrls: ["./subsidebar-industries.component.scss"],
})
export class SubsidebarIndustriesComponent implements OnInit {
  currPage: string = null;

  constructor(
    private route: ActivatedRoute,
    public appConstants: AppConstants
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      this.currPage = paramMap.get("serviceSlug");
    });
  }

  isActive(serviceItem: string): boolean {
    return this.currPage === serviceItem;
  }
}
