import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppConstants } from "src/app/utils/app-contants";

@Component({
  selector: "app-ourservices",
  templateUrl: "./ourservices.component.html",
  styleUrls: ["./ourservices.component.scss"]
})
export class OurservicesComponent implements OnInit {
  currPage: string = null;

  currentYear: number = new Date().getFullYear();

  constructor(private route: ActivatedRoute, public appConstants: AppConstants) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.currPage = paramMap.get("serviceSlug");
    });

    $('.float-nav').click(function () {
      $('.float-main-nav, .menu-btn').toggleClass('active');
    });
  }

  isActive(serviceItem: string): boolean {
    return this.currPage === serviceItem;
  }
}
