import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
//t
export class TechnologiesComponent implements OnInit {
  fragment: string;
  subscription: Subscription;

  constructor(private route: ActivatedRoute,) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.subscription = this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      setTimeout(() => this.scrollToAnchor(), 10);
    });
  }

  scrollToAnchor(): void {
    try {
      if (this.fragment) {
        const element = document.getElementById(this.fragment);
        const offset = 55;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
        });
      }
    } catch (e) { }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
