import { Component, OnInit, ViewChild } from "@angular/core";
import { SwiperConfigInterface, SwiperComponent } from "ngx-swiper-wrapper";
import { timer } from "rxjs/internal/observable/timer";
import { Subscription } from "rxjs";

@Component({
  selector: "app-homehero",
  templateUrl: "./homehero.component.html",
  styleUrls: ["./homehero.component.scss"],
})
export class HomeheroComponent implements OnInit {

  resetSlideTimer = timer(10000, 0); //Timer to trigger after 10 seconds
  sliderLoopTimer = timer(10000, 0); //Timer to trigger after 6 seconds
  sliderResetsub: Subscription;
  sliderLoopSub: Subscription;

  public config: SwiperConfigInterface = {
    navigation: false,
  };

  totalSlides: number = 6;
  selectedIndex: number = 0;
  showMainSliders = true;
  subSectionIndex = -1;

  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;

  ngOnInit() {
    $(function () {
      $(".fullheight").css({
        minHeight: $(window).innerHeight() + "px",
      });
      $(window).resize(function () {
        $(".fullheight").css({
          minHeight: $(window).innerHeight() + "px",
        });
      });
    });

    this.myStyle = {
      width: "100%",
      height: "100%",
      "z-index": -1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };

    this.myParams = {
      particles: {
        number: {
          value: 100,
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
        },
        width: {
          value: 5,
        },
        speed: {
          value: 2,
        },
      },
    };

    this.showNextSlideAfterDelay();
  }

  ngonDesctroy() {

  }

  /**
   * Method call when next button us pressed
   */
  showNextPage() {
    let currentIndex: number = this.getCurrentIndex();
    var newIndex: number = 0;
    if (currentIndex !== this.totalSlides - 1) {
      newIndex = currentIndex + 1;
    }
    this.setCurrentIndex(newIndex);
  }

  /**
   * Method call when previous button us pressed
   */
  showPreviousPage() {
    let currentIndex: number = this.getCurrentIndex();
    var newIndex: number = this.totalSlides - 1;
    if (currentIndex !== 0) {
      newIndex = currentIndex - 1;
    }
    this.setCurrentIndex(newIndex);
  }

  /**
   * This listener is triggred on each switch between slides.
   * @param index is the value of current index of slides
   */
  onIndexChange(index: number): void {
    this.setCurrentIndex(index);
    this.showNextSlideAfterDelay();
  }

  /**
   * Method to check if the passed index is the current index of slider
   * @param index
   */
  isIndexOf(index: number): boolean {
    return index === this.getCurrentIndex();
  }

  /**
   * Method returns current index of slider
   */
  getCurrentIndex(): number {
    return this.selectedIndex;
  }

  /**
   * Update the current index
   * @param newIndex
   */
  setCurrentIndex(newIndex: number) {
    if (this.componentRef != null) {
      this.componentRef.directiveRef.setIndex(newIndex);
    }
    this.selectedIndex = newIndex;
  }

  /**
   * Start timer to reset slides when sub slides are loaded
   */
  resetSlidesAfterDelay() {
    this.refreshTimer();
    this.sliderResetsub = this.resetSlideTimer.subscribe(() => {
      this.showMainSliders = true;
      this.subSectionIndex = -1;
      this.setCurrentIndex(0)
      this.refreshTimer();
      this.showNextSlideAfterDelay();
    });
  }

  /**
   * Stop the timer subsciption
   */
  refreshTimer(): void {
    if (this.sliderResetsub != null) {
      this.sliderResetsub.unsubscribe();
    }
  }

  /**
   * Show sub sections
   * @param index
   */
  showSubSections(index: number) {
    this.showMainSliders = false;
    this.subSectionIndex = index;
    this.resetSlidesAfterDelay();
    this.refreshSliderLoopTimer();
  }

  /**
   * Method to check if the passed index is the current subsection
   * @param index
   */
  isSubSectionOf(index: number): boolean {
    return index === this.subSectionIndex;
  }

  /**
   * Start timer to show next slide automatically
   */
  private showNextSlideAfterDelay() {
    this.refreshSliderLoopTimer();
    this.sliderLoopSub = this.sliderLoopTimer.subscribe(() => {
      this.showNextPage()
    });
  }

  /**
   * Stop the timer subsciption
   */
  private refreshSliderLoopTimer(): void {
    if (this.sliderLoopSub != null) {
      this.sliderLoopSub.unsubscribe();
    }
  }

}
