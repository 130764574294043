import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-solution-provider",
  templateUrl: "./solution-provider.component.html",
  styleUrls: ["./solution-provider.component.scss"],
})
export class SolutionProviderComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    $('.float-nav').click(function () {
      $('.float-main-nav, .menu-btn').toggleClass('active');
    });
  }
}
