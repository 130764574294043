export class  ApiConstants{
    apiUrls = { 
        letsTalk: '/content/quoteActionAPI.php',
        contactUs: '/content/contactActionAPI.php',
    }
    enquiryFormData = {
        username: 'username',
        companyname: 'companyname',
        email: 'email',
        phone: 'phone',
        requirements: 'requirements',
        
        firstname: 'firstname',
        lastname: 'lastname',
        title: 'title',
        city: 'city',
        state: 'state',
        country: 'country',
        comments: 'comments',
        industry:'industry',
      };
}