import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { ApiConstants } from '../utils/api-constants';

@Injectable({
  providedIn: 'root'
})
export class CustomerSupportService {

  constructor(private networkService: NetworkService, private apiConstants: ApiConstants) { }

  letsTalk(params): Observable<any> {
    const formData = new FormData();
    formData.append(this.apiConstants.enquiryFormData.username, params.username);
    formData.append(this.apiConstants.enquiryFormData.companyname, params.companyname);
    formData.append(this.apiConstants.enquiryFormData.email, params.email);
    formData.append(this.apiConstants.enquiryFormData.phone, params.phone);
    formData.append(this.apiConstants.enquiryFormData.requirements, params.requirements);

    return this.networkService.postRequest(formData, this.apiConstants.apiUrls.letsTalk);
  }

  contactUs(params): Observable<any> {
    const formData = new FormData();
    formData.append(this.apiConstants.enquiryFormData.firstname, params.firstname);
    formData.append(this.apiConstants.enquiryFormData.lastname, params.lastname);
    formData.append(this.apiConstants.enquiryFormData.city, params.city);
    formData.append(this.apiConstants.enquiryFormData.companyname, params.companyname);
    formData.append(this.apiConstants.enquiryFormData.state, params.state);
    formData.append(this.apiConstants.enquiryFormData.country, params.country);
    formData.append(this.apiConstants.enquiryFormData.industry, params.industry);
    formData.append(this.apiConstants.enquiryFormData.email, params.email);
    formData.append(this.apiConstants.enquiryFormData.comments, params.comments);

    return this.networkService.postRequest(formData, this.apiConstants.apiUrls.contactUs);
  }

}
