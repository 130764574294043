export class AppConstants {

  careerRequirementSplitCharactor = "\n";

  services = {
    application_services: "application-services",
    web_solutions: "web-solutions",
    design_services: "design-services",
    internet_marketing: "internet-marketing",
    mobile_apps: "mobile-apps",
    software_testing: "software-testing",
    infrastructure_support: "infrastructure-support",
    professional_services: "professional-services",
  };

  industries = {
    life_science: "life-science",
    bioinformatics: "bioinformatics",
    healthcare: "healthcare",
    personal_care: "personal-care",
    travel_hospitality: "travel-hospitality",
    retail: "retail",
    automotive: "automotive",
    education: "education",
    public_sector: "public-sector",
    enterprises_solutions: "enterprises-solutions",
    meteorology_weather: "meteorology-weather",
  };

  others = {
    engagement_model: "engagement-model",
    customer_engagement: "customer-engagement",
    trusted_partner: "trusted-partner",
    solution_provider: "solution-provider",
    startup_enterprises: "startup-enterprises",
  };

  patterns = {
    email: '^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$',
    phone: '[+]?[0-9]+',
  };

  messages = {
    supportMessage1: "Thank You! ",
    supportMessage2: "Your Message sent successfully!",
    supportMessage3: "We will get in touch."
  }
}
