import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-trusted-partner",
  templateUrl: "./trusted-partner.component.html",
  styleUrls: ["./trusted-partner.component.scss"],
})
export class TrustedPartnerComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    $('.float-nav').click(function () {
      $('.float-main-nav, .menu-btn').toggleClass('active');
    });
  }
}
