import { Injectable } from '@angular/core';
import { Career } from '../models/career';
import careerJSON from '../data/careers.json';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class CareerService {

  constructor(private httpService: HttpClient) { }

  getcareerListing() {
    //return careerJSON as Career[];
    return this.httpService.get('https://www.calpinetech.com/data/careers.json');
    
  }

}