import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import eventJson from '../data/event.json'
import { events } from '../models/events';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private httpService: HttpClient, @Inject(DOCUMENT) private document: Document) { }
  getEventList():Observable <any>{
    // return eventJson as events[]
    return this.httpService.get(this.document.baseURI+'data/event.json');

  }
}
