import { Component, OnInit } from "@angular/core";

/**
 * Section Types available in the UI
 */
enum SectionType {
  IOT,
  Apps,
  Ecommerce,
}

@Component({
  selector: "app-homesolutions",
  templateUrl: "./homesolutions.component.html",
  styleUrls: ["./homesolutions.component.scss"],
})
export class HomesolutionsComponent implements OnInit {
  constructor() {}

  sectionType = SectionType; // Store a reference to the enum
  activeSection: SectionType = SectionType.IOT;

  ngOnInit() {}

  /**
   * This will trigger when section titles are clicked
   * @param section : SectionType on which clicked
   */
  onClickSection(section: SectionType) {
    this.activeSection = section;
    this.scrollToTop();
  }

  /**
   * Check if the current section matches the activated section
   * @param section
   */
  isSectionActive(section: SectionType): boolean {
    return this.activeSection === section;
  }

  private scrollToTop() {
    const element = document.getElementById('homeSol');
    element.scrollIntoView({behavior: "smooth"})
  }

}
