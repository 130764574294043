import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-application-services",
  templateUrl: "./application-services.component.html",
  styleUrls: ["./application-services.component.scss"]
})
export class ApplicationServicesComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0)
  }
}
