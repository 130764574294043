import { Component, OnInit } from "@angular/core";


@Component({
  selector: "app-engagement-model",
  templateUrl: "./engagement-model.component.html",
  styleUrls: ["./engagement-model.component.scss"],
})
export class EngagementModelComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    $('.float-nav').click(function () {
      $('.float-main-nav, .menu-btn').toggleClass('active');
    });
  }
}
