import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-meteorology-weather",
  templateUrl: "./meteorology-weather.component.html",
  styleUrls: ["./meteorology-weather.component.scss"],
})
export class MeteorologyWeatherComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
