import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/utils/app-contants';
import { CustomerSupportService } from 'src/app/services/customer-support.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-lets-talk',
  templateUrl: './lets-talk.component.html',
  styleUrls: ['./lets-talk.component.scss']
})
export class LetsTalkComponent implements OnInit {

  public supportForm: FormGroup;
  public isFormSubmitted: boolean = false;
  public letsTalkDialogueVisible = true;

  constructor(
    private formBuilder: FormBuilder,
    public appConstants: AppConstants,
    private supportService: CustomerSupportService,
    public dialogRef: MatDialogRef<LetsTalkComponent>,
  ) { }

  ngOnInit() {
    this.supportForm = this.formBuilder.group({
      username: new FormControl('',
        [Validators.required]),
      email: new FormControl('', [Validators.compose([
        Validators.required,
        Validators.pattern(this.appConstants.patterns.email)])]),
      phone: new FormControl('', [Validators.compose([
        Validators.pattern(this.appConstants.patterns.phone),
        Validators.maxLength(20)])]),
      companyname: new FormControl('',),
      requirements: new FormControl('',),
    });
  }

  public sendEnquiry() {
    if (this.supportForm.valid) {
      this.supportService.letsTalk(this.supportForm.value).subscribe(result => {
        if (result.success) {
          this.isFormSubmitted = true;
        } else {
          //TODO: Handle error
        }
      }, (error) => {
        //TODO: Handle error
      });
    }
  }

  public onCloseClick() {
    if (this.letsTalkDialogueVisible) {
      this.dialogRef.close();
      return;
    }
  }

}
