import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { ModalModule } from "./_modal";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/landing-page/footer/footer.component";
import { HomeheroComponent } from "./components/landing-page/homehero/homehero.component";
import { HomesolutionsComponent } from "./components/landing-page/homesolutions/homesolutions.component";
import { HomefeaturesComponent } from "./components/landing-page/homefeatures/homefeatures.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { ParticlesModule } from "angular-particle";
import { AccordionModule } from "primeng/accordion";
import { HomeComponent } from "./components/landing-page/home/home.component";
import { OurservicesComponent } from "./components/ourservices/ourservices.component";
import { ApplicationServicesComponent } from "./components/ourservices/application-services/application-services.component";
import { WebSolutionsComponent } from "./components/ourservices/web-solutions/web-solutions.component";
import { SubsidebarComponent } from "./components/ourservices/subsidebar/subsidebar.component";
import { DesignServicesComponent } from "./components/ourservices/design-services/design-services.component";
import { InternetMarketingComponent } from "./components/ourservices/internet-marketing/internet-marketing.component";
import { MobileAppsComponent } from "./components/ourservices/mobile-apps/mobile-apps.component";
import { SoftwareTestingComponent } from "./components/ourservices/software-testing/software-testing.component";
import { InfrastructureSupportComponent } from "./components/ourservices/infrastructure-support/infrastructure-support.component";
import { ProfessionalServicesComponent } from "./components/ourservices/professional-services/professional-services.component";
import { IndustriesComponent } from "./components/industries/industries.component";
import { LifeScienceComponent } from "./components/industries/life-science/life-science.component";
import { SubsidebarIndustriesComponent } from "./components/industries/subsidebar-industries/subsidebar-industries.component";
import { BioinformaticsComponent } from "./components/industries/bioinformatics/bioinformatics.component";
import { HealthcareComponent } from "./components/industries/healthcare/healthcare.component";
import { PersonalCareComponent } from "./components/industries/personal-care/personal-care.component";
import { AutomotiveComponent } from "./components/industries/automotive/automotive.component";
import { TravelHospitalityComponent } from "./components/industries/travel-hospitality/travel-hospitality.component";
import { EcommerceComponent } from "./components/industries/ecommerce/ecommerce.component";
import { PublicSectorComponent } from "./components/industries/public-sector/public-sector.component";
import { EnterprisesSolutionsComponent } from "./components/industries/enterprises-solutions/enterprises-solutions.component";
import { ProcessComponent } from "./components/process/process.component";

import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { AboutusComponent } from "./components/aboutus/aboutus.component";
import { CareerComponent } from "./components/career/career.component";
import { LetsTalkComponent } from "./components/lets-talk/lets-talk.component";
import { MaterialModule } from "./material/material.module";
import { ContactusComponent } from "./components/contactus/contactus.component";
import { AppConstants } from "./utils/app-contants";
import { DragScrollModule } from "ngx-drag-scroll";
import { RetailComponent } from "./components/industries/retail/retail.component";
import { EducationComponent } from "./components/industries/education/education.component";
import { MeteorologyWeatherComponent } from "./components/industries/meteorology-weather/meteorology-weather.component";
import { EngagementModelComponent } from "./components/engagement-model/engagement-model.component";
import { CustomerEngagementComponent } from "./components/customer-engagement/customer-engagement.component";
import { TrustedPartnerComponent } from "./components/trusted-partner/trusted-partner.component";
import { SolutionProviderComponent } from "./components/solution-provider/solution-provider.component";
import { StartupEnterprisesComponent } from "./components/startup-enterprises/startup-enterprises.component";
import { SidebarModelsComponent } from "./components/sidebar-models/sidebar-models.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { QuicklinksServicesComponent } from "./components/ourservices/quicklinks-services/quicklinks-services.component";
import { QuicklinksIndustriesComponent } from "./components/industries/quicklinks-industries/quicklinks-industries.component";
import { TechnologiesComponent } from "./components/technologies/technologies.component";
import { SkillsetComponent } from "./components/skillset/skillset.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ApiConstants } from './utils/api-constants';
import { SubFooterComponent } from './components/sub-footer/sub-footer.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeheroComponent,
    HomesolutionsComponent,
    HomefeaturesComponent,
    HomeComponent,
    ApplicationServicesComponent,
    WebSolutionsComponent,
    OurservicesComponent,
    SubsidebarComponent,
    DesignServicesComponent,
    InternetMarketingComponent,
    MobileAppsComponent,
    SoftwareTestingComponent,
    InfrastructureSupportComponent,
    ProfessionalServicesComponent,
    IndustriesComponent,
    LifeScienceComponent,
    SubsidebarIndustriesComponent,
    BioinformaticsComponent,
    HealthcareComponent,
    PersonalCareComponent,
    AutomotiveComponent,
    TravelHospitalityComponent,
    EcommerceComponent,
    PublicSectorComponent,
    EnterprisesSolutionsComponent,
    ProcessComponent,
    AboutusComponent,
    CareerComponent,
    LetsTalkComponent,
    ContactusComponent,
    RetailComponent,
    EducationComponent,
    MeteorologyWeatherComponent,
    EngagementModelComponent,
    CustomerEngagementComponent,
    TrustedPartnerComponent,
    SolutionProviderComponent,
    StartupEnterprisesComponent,
    SidebarModelsComponent,
    QuicklinksServicesComponent,
    QuicklinksIndustriesComponent,
    TechnologiesComponent,
    SkillsetComponent,
    SubFooterComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    ModalModule,
    ParticlesModule,
    AccordionModule,
    SwiperModule,
    MaterialModule,
    MatInputModule,
    MatSelectModule,
    DragScrollModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    AppConstants,
    ApiConstants,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [LetsTalkComponent],
})
export class AppModule { }
