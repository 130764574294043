import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infrastructure-support',
  templateUrl: './infrastructure-support.component.html',
  styleUrls: ['./infrastructure-support.component.scss']
})
export class InfrastructureSupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

}
