import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppConstants } from "src/app/utils/app-contants";

@Component({
  selector: "app-industries",
  templateUrl: "./industries.component.html",
  styleUrls: ["./industries.component.scss"],
})
export class IndustriesComponent implements OnInit {
  currPage: string = null;
  currentYear: number = new Date().getFullYear();
  constructor(
    private route: ActivatedRoute,
    public appConstants: AppConstants
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.route.paramMap.subscribe((paramMap) => {
      this.currPage = paramMap.get("serviceSlug");
    });

    $('.float-nav').click(function () {
      $('.float-main-nav, .menu-btn').toggleClass('active');
    });
  }

  isActive(serviceItem: string): boolean {
    return this.currPage === serviceItem;
  }
}
